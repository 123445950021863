/*
 * Mobile Nav
 */
 
import { $window, $document, $body } from '../utils/globals.js';

const $header = $('.js-header');
const $mobileNav = $('.js-mobile-nav');
const $mobileNavTrigger = $('.js-mobile-nav-trigger');
const MOBILE_WIDTH = 1024;

function hasOpenMobileNav() {
	return $body.hasClass('has-open-nav');
}

function openMobileNav() {
	$mobileNavTrigger.addClass('is-active');
	$mobileNav.addClass('is-active');
	$body.addClass('has-open-nav');
	$header.addClass('is-active');
}

function closeMobileNav() {
	$mobileNavTrigger.removeClass('is-active');
	$mobileNav.removeClass('is-active');
	$body.removeClass('has-open-nav');
	$header.removeClass('is-active');
}

$mobileNavTrigger.on('click', function(e) {
	e.preventDefault();

	if (!($window.width() <= MOBILE_WIDTH)) return;

	hasOpenMobileNav() ? closeMobileNav() : openMobileNav();
});

$window.on('resize', _.debounce(function() {
	if (!($window.width() <= MOBILE_WIDTH)) {
		closeMobileNav();
	}
}, 100));