/*
 * Animations
 */
 
import { $window, $document, $body } from '../utils/globals.js';
import AOS from 'aos';

function presetAnimations() {
	const $sequence = $('');
	const timingStart = 250;
	const timingStep = 130;
	const timingGroup = 1;
	let i = 0;
	let transitionDelay = 0;

	$sequence.each(function() {
			const $sequence = $(this);

			transitionDelay = timingStart + Math.floor(i / timingGroup) * timingStep;
			$sequence.css({
					transitionDelay: transitionDelay / 1000 + 's',
			});
			i++;
	});

	const $fadeIn = $('.section h1, .section h2, .section h3, .section h4, .section h5, .section h6, .section p, .section ul:not([class]) li, .section__socials li, .section a.btn, .section-banner h2, .section-banner h3, .footer__inner, .section--contact, .section__head, .section--reviews .section__col, .section--reviews .section__form, .section__road li');

	$fadeIn.each(function() {
			const $fadeIn = $(this);

			$fadeIn.attr({
					'data-aos': 'fade-in'
			});
	});

	const $fadeUp = $('.section__tiles li, .section__item, .section__video, .section__box, .section__box-lg, .section__logos li, .section--article figure, .section--article .section__list li, .section__col--notes, .section--about figure');

	$fadeUp.each(function() {
			const $fadeUp = $(this);

			$fadeUp.attr({
					'data-aos': 'fade-up'
			});
	});

	const $fadeDown = $('.section__road');

	$fadeDown.each(function() {
			const $fadeDown = $(this);

			$fadeDown.attr({
					'data-aos': 'fade-down'
			});
	});

	const $zoomIn = $('.section__decoration, .section--road .section__logo');

	$zoomIn.each(function() {
		const $zoomIn = $(this);

		$zoomIn.attr({
			'data-aos': 'zoom-in'
		});
	});
}

function calcOffset() {
	const winHeight = window.innerHeight;
	const winVh = winHeight / 100;

	return parseInt(20 * winVh);
}

function createInstance() {
	AOS.init({
		anchorPlacement: 'top-bottom',
		duration: 1600,
		once: true,
		// offset: calcOffset()
	});
}

$document.ready(function() { 
	// presetAnimations();
});

$window.on('load', function() {
	$body.addClass('is-loaded');
	createInstance();
});
