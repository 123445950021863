/**
 * Bars
 */

const $bar = $('.js-bar');

$bar.each(function(index, el) {
  const $bar = $(this);
  const $barClose = $bar.find('.js-bar-close');

	 $barClose.on('click', function(e) {
      e.preventDefault();
      $bar.addClass('hidden');
  });
});
