/*
* Bootstrap
*/
require('./bootstrap');

/*
 * Isotope
 */
require('isotope-layout');

/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */
import './modules/videos';
import './modules/mobile-nav';
import './modules/bars';
import './modules/animations';
