/**
 * Videos
 */

const $video = $('.js-video');

$video.each(function(index, el) {
  const $video = $(this);
  const $videoTrigger = $video.find('.js-video-trigger');
  const $videoMedia = $video.find('video');

	 $videoTrigger.on('click', function(e) {
      e.preventDefault();
      $video.addClass('is-playing');
      $videoMedia[0].play();
  });

  $videoMedia.on('click', function() {
      $videoMedia[0].pause();
      $video.removeClass('is-playing');
  });
});
